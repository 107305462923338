import { render, staticRenderFns } from "./TheHome.vue?vue&type=template&id=33b2161d&scoped=true"
import script from "./TheHome.vue?vue&type=script&lang=js"
export * from "./TheHome.vue?vue&type=script&lang=js"
import style0 from "./TheHome.vue?vue&type=style&index=0&id=33b2161d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b2161d",
  null
  
)

export default component.exports